import { Canvas } from '@react-three/fiber'
import { TrackballControls } from '@react-three/drei'
import { useState, useEffect, useCallback, useRef } from 'react'
import { Viewcube } from './ViewCube.js'
import { button, useControls } from 'leva'
import { Cloud } from './cloud.js'
import SentenceContext from './context/SentenceContext.js'
import DeviceContext from './context/DeviceContext.js'
import { Perf } from 'r3f-perf'
import languages from './languages.js'
import Hud from './Hud.js'
import { DialogProvider } from './context/DialogContext.js'
import UserContext from './context/UserContext.js'
import styled, { css, createGlobalStyle } from 'styled-components'
import Button from '@mui/material/Button'
import { LOGIN_MUTATION, REGISTER_MUTATION } from './query/gqlQueries.js'
import { useMutation } from '@apollo/client'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LockIcon from '@mui/icons-material/Lock'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './base.module.scss'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText' // Import SplitText plugin
import { useGSAP } from '@gsap/react'

import logo from './assets/Vector.svg'

const languageOptions = Object.values(languages)
const shuffledLanguageOptions = Object.values(languages).sort(() => Math.random() - 0.5)
const owners = ['My', 'Tatoeba']
// const ownersExtended = ['My', 'Tatoeba', 'Others', 'Our']
// const labels = ['Sentences', 'Words', 'Conversations', 'Monologue', 'Thoughts', 'Projects', 'Self Improvement', 'Tasks', 'Inspiration']
const labels = ['Sentences']

export default function App() {
  // Active sentence and current translation
  const [activeSentenceObj, setActiveSentenceObj] = useState(null)
  const [activeTranslationObj, setActiveTranslationObj] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [username, setUsername] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loginUsername, setLoginUsername] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [registerUsername, setRegisterUsername] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [targetLanguage, setTargetLanguage] = useState('Portuguese')
  const [sourceLanguage, setSourceLanguage] = useState('English')
  const [owner, setOwner] = useState('My')
  const [label, setLabel] = useState('Sentences')
  const FormRef = useRef(null)
  const LogoRef = useRef(null)
  const ButtonRef = useRef(null)

  const [login] = useMutation(LOGIN_MUTATION)
  const [register] = useMutation(REGISTER_MUTATION)

  const handleLogin = async () => {
    try {
      setLoading(true)
      const response = await login({ variables: { username: loginUsername, password: loginPassword } })
      const token = response.data.login.token
      localStorage.setItem('token', token) // Store the token in local storage
      setIsLoggedIn(true)
      setUsername(loginUsername)
      setLoading(false)
      setFeedbackMessage('Login successful!')
    } catch (error) {
      setLoading(true)
      setFeedbackMessage('Login failed: ' + error.message)
      setLoading(false)
    }
  }

  const handleRegister = async () => {
    try {
      await register({ variables: { username: registerUsername, password: registerPassword } })
      setFeedbackMessage('Registration successful! Please login.')
    } catch (error) {
      setFeedbackMessage('Registration failed: ' + error.message)
    }
  }

  const [queryData, setQueryData] = useState([
    {
      sourceLanguage: 'eng',
      source: 'What do you want to learn today',
      sourceId: null,
      sourceType: null,
      targetLanguage: 'por',
      target: 'O que é que quer aprender hoje?',
      targetId: null,
      targetType: null
    }
  ])

  // Change data into source and target sentence
  const handleData = (data) => {
    if (data.length === 0) {
      setQueryData([
        {
          sourceLanguage: 'eng',
          source: 'No matching sentences found',
          sourceId: null,
          sourceType: null,
          targetLanguage: 'por',
          target: 'Não foram encontradas frases correspondentes',
          targetId: null,
          targetType: null
        }
      ])
    } else {
      setQueryData(data)
    }
  }

  useEffect(() => {
    const checkIfMobile = () => {
      return (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1 ||
        window.matchMedia('only screen and (max-width: 767px)').matches ||
        navigator.userAgent.match(/iPad/i) !== null
      )
    }

    setIsMobile(checkIfMobile())
  }, [])

  let count, radius, fontSize, hoverColor, perfVisible, primaryColor, secondaryColor, tertiaryColor, voiceSpeed, tickerVisible

  if (process.env.REACT_APP_DEV === 'True') {
    // Assign values using useControls when in dev mode
    ;({ count, radius, fontSize, hoverColor, perfVisible, primaryColor, secondaryColor, tertiaryColor, voiceSpeed, tickerVisible } = useControls({
      count: { value: 4, min: 1, max: 16, step: 1 },
      radius: { value: 20, min: 10, max: 50, step: 1 },
      fontSize: { value: 1.2, min: 0.5, max: 5, step: 0.1 },
      primaryColor: { value: '#FFDE7D' },
      secondaryColor: { value: '#F2BE22' },
      tertiaryColor: { value: '#F29727' },
      hoverColor: { value: '#00ADB5' },
      voiceSpeed: { value: 0.75, min: 0.25, max: 1.5, step: 0.05 },
      removeFocus: button(() => resetActiveSentence()),
      perfVisible: false,
      tickerVisible: false
    }))
  } else {
    ;({ count, radius, fontSize, hoverColor, perfVisible, primaryColor, secondaryColor, tertiaryColor, voiceSpeed, tickerVisible } = {
      count: 4,
      radius: 20,
      fontSize: 1.2,
      primaryColor: '#FFDE7D',
      secondaryColor: '#F2BE22',
      tertiaryColor: '#F29727',
      hoverColor: '#00ADB5',
      voiceSpeed: 0.75,
      removeFocus: () => resetActiveSentence(),
      perfVisible: false,
      tickerVisible: false
    })
  }

  // Add the resetActiveSentence function
  const resetActiveSentence = () => {
    setActiveSentenceObj(null)
    setActiveTranslationObj(null)
  }

  useGSAP(() => {
    const tl = gsap.timeline()

    // Animate the logo into the center of the screen
    tl.from(LogoRef.current, {
      opacity: 0,
      y: -50,
      duration: 2,
      ease: 'power2.out'
    })

    // Animate the login form coming in from behind the logo to the right
    tl.from(
      FormRef.current,
      {
        opacity: 0,
        x: -50,
        duration: 1.5,
        ease: 'power2.out'
      },
      '-=0.5'
    ) // "-=1" overlaps the form animation with the logo animation by 1 second
    // Animate the login form coming in from behind the logo to the right
    tl.from(
      ButtonRef.current,
      {
        opacity: 0,
        y: +50,
        duration: 1.5,
        ease: 'power2.out'
      },
      '-=0.5'
    ) // "-=1" overlaps the form animation with the logo animation by 1 second
  }, [])

  if (!isLoggedIn) {
    return (
      <LoginDiv>
        {/* <div>
          <h2>Register</h2>
          <input type="text" placeholder="Username" value={registerUsername} onChange={(e) => setRegisterUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
          <Button onClick={handleRegister} variant="contained">Register</Button>
        </div>
        <p>{feedbackMessage}</p> */}
        <div className={styles.loginDiv}>
          <div className={styles.logoContainer} ref={LogoRef}>
            <img src={logo} />
          </div>
          <div className={styles.loginForm} ref={FormRef}>
            <TextField
              className="text-field-login"
              autoFocus
              label="Username"
              margin="dense"
              type="text"
              value={loginUsername}
              InputLabelProps={{
                style: { color: 'white' }
              }}
              sx={{
                input: { color: 'white' }
              }}
              onChange={(e) => setLoginUsername(e.target.value)}
              fullWidth
            />
            <TextField
              label="Password"
              margin="dense"
              type="password"
              placeholder="Password"
              fullWidth
              value={loginPassword}
              InputLabelProps={{
                style: { color: 'white', borderColor: 'white' }
              }}
              sx={{
                borderColor: 'text.primary',
                input: { color: 'white' }
              }}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
            <ButtonDiv className="button-div" ref={ButtonRef}>
              <Button onClick={handleLogin} variant="contained">
                Login
              </Button>
            </ButtonDiv>
            <p>{feedbackMessage}</p>
            {loading && <CircularProgress />}
          </div>
        </div>
      </LoginDiv>
    )
  }

  return (
    <>
      <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }}>
        {perfVisible && <Perf position="top-left" />}
        <fog attach="fog" args={['#202025', 0, 80]} />
        <DeviceContext.Provider value={{ isMobile }}>
          <SentenceContext.Provider value={{ activeSentenceObj, setActiveSentenceObj, activeTranslationObj, setActiveTranslationObj }}>
            <Cloud
              count={count}
              radius={radius}
              fontSize={fontSize}
              sentenceList={queryData}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              tertiaryColor={tertiaryColor}
              hoverColor={hoverColor}
              targetLanguage={targetLanguage}
              sourceLanguage={sourceLanguage}
              label={label}
              owner={owner}
              voiceSpeed={voiceSpeed}
            />
          </SentenceContext.Provider>
        </DeviceContext.Provider>
        <TrackballControls />
        {/* Change to first name of person using the app or username or first Letter */}
        {/* <Viewcube /> */}
      </Canvas>
      <UserContext.Provider value={{ username, isLoggedIn }}>
        <DeviceContext.Provider value={{ isMobile }}>
          <SentenceContext.Provider value={{ activeSentenceObj, setActiveSentenceObj, activeTranslationObj, setActiveTranslationObj }}>
            <DialogProvider>
              <Hud
                sourceLanguage={sourceLanguage}
                setSourceLanguage={setSourceLanguage}
                targetLanguage={targetLanguage}
                setTargetLanguage={setTargetLanguage}
                sentenceList={queryData}
                onData={handleData}
                label={label}
                setLabel={setLabel}
                owner={owner}
                setOwner={setOwner}
                labels={labels}
                owners={owners}
                languageOptions={languageOptions}
                tickerVisible={tickerVisible}
                voiceSpeed={voiceSpeed}
              />
            </DialogProvider>
          </SentenceContext.Provider>
        </DeviceContext.Provider>
      </UserContext.Provider>
    </>
  )
}

const LoginDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full height of the viewport
  background-color: #202025; // Or any other background color
  font-family: ;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-width: 400px;
  background: #202025;
  border-radius: 8px;
  background-color: white;
  @media (max-width: 900px) {
    min-width: unset;
  }
  h1 {
    font-family: 'Noah Regular';
  }
`

const ButtonDiv = styled.div`
  margin-top: 10px;
`
