const fonts = {
  eng: '/Caprasimo-Regular.ttf',
  nld: '/Inter-Bold.woff',
  spa: '/Inter-Bold.woff',
  por: '/Caprasimo-Regular.ttf',
  rus: '/Inter-Bold.woff',
  deu: '/Inter-Bold.woff',
  fra: '/Inter-Bold.woff',
  ita: '/IMFellFrenchCanon-Italic.tff',
  jpn: '/NotoSansJP-Medium.ttf',
  cmn: '/NotoSansSC-Regular.ttf',
  vie: '/Inter-Bold.woff',
  kor: '/NotoSansKR-Regular.otf',
  ara: '/NotoSansArabic-Regular.ttf',
  ell: '/Inter-Bold.woff',
  tur: '/Inter-Bold.woff'
}

export default fonts
