// a little function to help us with reordering the result
export const reorder = function(list, startIndex, endIndex) {
  var result = Array.from(list);
  var [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItems = function(count) {
  return Array.from({ length: count }, function(v, k) { return k; }).map(function(k) {
    return {
      id: "Item " + (k + 1),
      primary: "Speaker " + (k + 1),
      secondary: "Sentences are likely to be very long and take up a lot of space I wonder if these allow for overflow " + (k + 1)
    };
  });
};
