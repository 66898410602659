const deeplLanguagesMapping = {
  eng: 'EN', // English - Assuming default to US or GB, DeepL supports both
  rus: 'RU', // Russian
  ita: 'IT', // Italian
  deu: 'DE', // German
  fra: 'FR', // French
  por: 'PT-PT', // Portuguese - Assuming European Portuguese, there's also 'PT-BR' for Brazilian
  spa: 'ES', // Spanish
  jpn: 'JA', // Japanese
  nld: 'NL', // Dutch
  cmn: 'ZH', // Mandarin Chinese
  ara: 'AR', // Arabic
  ell: 'EL', // Greek
  swe: 'SV', // Swedish
  kor: 'KO',
  cin: 'ZH',
  tur: 'TR'
  // DeepL does not support Mandarin Chinese, Vietnamese, or Korean with two-letter codes directly in the provided list.
}

export const getDeeplLanguageAcronym = (threeLetterAcronym) => {
  return deeplLanguagesMapping[threeLetterAcronym]
}
