import { createContext } from "react";

const SentenceContext = createContext({
  activeSentenceObj: null,
  setActiveSentenceObj: () => {},
  activeTranslationObj: null,
  setActiveTranslationObj: () => {},
  isMobile: false
});

export default SentenceContext;
