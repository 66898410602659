// Import required components and hooks
import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import { DialogContext } from '../context/DialogContext.js'

// Import graphQL Mutation
import { useMutation, gql } from '@apollo/client'

// Import languages
import languages from '../languages.js'
import { Checkbox, FormControlLabel, Box } from '@mui/material'
import TagsInput from './dialoginputs/TagsInput.js'
import UserContext from '../context/UserContext.js'
import TranslateIcon from '@mui/icons-material/Translate'
import translate from 'deepl'
import { getDeeplLanguageAcronym } from '../query/deeplLanguages.js'

const ADD_SENTENCE_MUTATION = gql`
  mutation AddUserSentence($username: String!, $sourceText: String!, $targetText: String!, $sourceLang: String!, $targetLang: String!, $tags: [String]!) {
    AddUserSentence(username: $username, sourceText: $sourceText, targetText: $targetText, sourceLang: $sourceLang, targetLang: $targetLang, tags: $tags) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

// Create the AddSentenceDialog component
function AddSentenceDialog() {
  const { dialogStates, openDialog, closeDialog } = React.useContext(DialogContext)
  const [isPublic, setIsPublic] = useState(false)
  const [sourceLanguage, setSourceLanguage] = useState(Object.keys(languages)[0])
  const [targetLanguage, setTargetLanguage] = useState(Object.keys(languages)[5])
  const [sourceSentence, setSourceSentence] = useState('')
  const [targetSentence, setTargetSentence] = useState('')
  const [tags, setTags] = useState([])
  const { username, isLoggedIn } = useContext(UserContext)

  // GraphQL
  const [addUserSentence, { data, loading, error }] = useMutation(ADD_SENTENCE_MUTATION)

  const handleClose = () => {
    closeDialog('dialogAddSentence')
  }

  const handleSourceLanguageChange = (event) => {
    setSourceLanguage(event.target.value)
  }

  const handleTargetLanguageChange = (event) => {
    setTargetLanguage(event.target.value)
  }

  const handleSourceSentenceChange = (event) => {
    setSourceSentence(event.target.value)
  }

  const handleTargetSentenceChange = (event) => {
    setTargetSentence(event.target.value)
  }

  const handleSave = () => {
    addUserSentence({
      variables: {
        username: username,
        sourceText: sourceSentence,
        targetText: targetSentence,
        sourceLang: sourceLanguage,
        targetLang: targetLanguage,
        tags: tags
      }
    })
    handleClose()
    setSourceSentence('')
    setTargetSentence('')
  }

  const translateText = () => {
    const sourceLangCode = getDeeplLanguageAcronym(sourceLanguage)
    const targetLangCode = getDeeplLanguageAcronym(targetLanguage)

    if (!sourceLangCode || !targetLangCode) {
      console.error('Translation not supported for selected language')
      return
    }

    translate({
      free_api: true,
      text: sourceSentence,
      target_lang: targetLangCode,
      auth_key: process.env.REACT_APP_DEEPL_API_KEY // Ensure your API key is securely stored
    })
      .then((result) => {
        setTargetSentence(result.data.translations[0].text)
      })
      .catch((error) => {
        console.error('Translation error:', error)
      })
  }

  return (
    <>
      <Dialog open={dialogStates['dialogAddSentence']} onClose={() => closeDialog('dialogAddSentence')}>
        <DialogTitle>Add a new sentence</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="sourceSentence"
            label="Source Sentence"
            type="text"
            fullWidth
            value={sourceSentence}
            onChange={handleSourceSentenceChange}
          />
          <Select labelId="source-language-label" id="sourceLanguage" value={sourceLanguage} onChange={handleSourceLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <DialogActions>
            <Button onClick={translateText}>
              Translate <TranslateIcon />
            </Button>
          </DialogActions>
          <TextField
            margin="dense"
            id="targetSentence"
            label="Target Sentence"
            type="text"
            fullWidth
            value={targetSentence}
            onChange={handleTargetSentenceChange}
          />
          <Select labelId="target-language-label" id="targetLanguage" value={targetLanguage} onChange={handleTargetLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {/* <FormControlLabel
            control={<Checkbox checked={isPublic} onChange={(event) => setIsPublic(event.target.checked)} name="isPublic" color="primary" />}
            label="Public"
          /> */}
          <Box mt={2}>
            <TagsInput selectedTags={setTags} fullWidth variant="outlined" id="tags" name="tags" placeholder="Add Tags" label="Tags" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

// Export AddSentenceDialog component
export default AddSentenceDialog
