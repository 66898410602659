import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FavoriteIcon from '@mui/icons-material/Favorite'
import CloseIcon from '@mui/icons-material/Close'
import ShareIcon from '@mui/icons-material/Share'
import SentenceContext from '../context/SentenceContext'
import styled, { css } from 'styled-components'
import UserContext from '../context/UserContext.js'
import { CHECK_SAVED_STATUS_QUERY, SAVE_SENTENCES_MUTATION } from '../query/gqlQueries.js'

const InfoPanel = ({ saveButton }) => {
  const { activeSentenceObj, setActiveSentenceObj, activeTranslationObj, setActiveTranslationObj } = useContext(SentenceContext)
  const { username, isLoggedIn } = useContext(UserContext)
  const [getSavedStatus, { data, loading, error }] = useLazyQuery(CHECK_SAVED_STATUS_QUERY)
  const [saveSentences] = useMutation(SAVE_SENTENCES_MUTATION)
  const [buttonColor, setButtonColor] = useState('default') // default color

  useEffect(() => {
    if (activeSentenceObj !== null) {
      getSavedStatus({
        variables: {
          username,
          sourceSentenceId: activeSentenceObj.sourceId,
          translationSentenceId: activeSentenceObj.targetId
        }
      })
    }
  }, [activeSentenceObj, getSavedStatus, username])

  useEffect(() => {
    if (data && data.checkSavedStatus.sourceSaved && data.checkSavedStatus.translationSaved) {
      setButtonColor('error') // change the color when the sentences are saved
    } else {
      setButtonColor('default') // reset the color if the sentences are not saved
    }
  }, [data])

  // if (loading) return <p>Loading...</p>
  // if (error) return <p>Error: {error.message}</p>

  const handleSaveClick = async () => {
    await saveSentences({
      variables: {
        username,
        sourceSentenceId: activeSentenceObj.sourceId,
        translationSentenceId: activeSentenceObj.targetId
      },
      refetchQueries: [
        {
          query: CHECK_SAVED_STATUS_QUERY,
          variables: {
            username,
            sourceSentenceId: activeSentenceObj.sourceId,
            translationSentenceId: activeSentenceObj.targetId
          }
        }
      ]
    })
  }

  const handleCloseClick = () => {
    // Logic for the close button
    setActiveSentenceObj(null)
  }

  return (
    <PanelContainer
      style={{
        pointerEvents: activeSentenceObj ? 'auto' : 'none'
      }}
      show={activeSentenceObj !== null}>
      {activeSentenceObj && (
        <Stack direction="row" spacing={2}>
          {saveButton.active && (
            <Button variant="contained" startIcon={<FavoriteIcon color={buttonColor} />} onClick={handleSaveClick}>
              Save
            </Button>
          )}
          <Button variant="contained" startIcon={<CloseIcon />} onClick={handleCloseClick}>
            Close
          </Button>
        </Stack>
      )}
    </PanelContainer>
  )
}

const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 60px;
  border-radius: 8px;
  transition: opacity 1s ease;
  opacity: 0;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`

export default InfoPanel
