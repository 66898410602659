import React, { useState, useRef, useEffect } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import AssignmentIcon from '@mui/icons-material/Assignment'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import { useQuery } from '@apollo/client'
import { GET_PROJECTS_QUERY } from '../query/gqlQueries.js'

import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'

const TimerContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0,123,255, 70%) ;
  padding: 10px 20px;
  border-radius: 5px;
  gap: 10px;
  position: relative; // Make relative to position SpeedDial absolutely inside it
`


const TimerButtonsContainer = styled.div`

  display: flex;
  align-items: flex-start;
  max-height: 60px;
  gap: 20px;
  margin-right: 20px;`


const TimerButton = styled.button`
  margin: 0 0px;
  padding: 5px 10px;
  border: none;
  background-color: white;
  color: #007bff;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
 
  &:hover {
    background-color: #0056b3;
    color: white;
    font-weight: 600;
  }
`

const CountdownDisplay = styled.span`
  color: white;
  margin-right: 15px;
  font-size: 1.2em;
`

const CountdownTimer = ({ initialTime = 25 * 60 * 1000, username, addTask }) => {
  const countdownRef = useRef()
  const [targetDate, setTargetDate] = useState(Date.now() + initialTime)
  const [isRunning, setIsRunning] = useState(false)
  const [open, setOpen] = useState(false) // State to control SpeedDial open state

  const { data, loading, error } = useQuery(GET_PROJECTS_QUERY, {
    variables: { username }
  })

  useEffect(() => {
    setTargetDate(Date.now() + initialTime)
  }, [initialTime])

  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <CountdownDisplay>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </CountdownDisplay>
    )
  }

  const toggleCountdown = () => {
    if (isRunning) {
      countdownRef.current.pause()
    } else {
      countdownRef.current.start()
    }
    setIsRunning(!isRunning)
  }

  const resetCountdown = () => {
    const newTargetDate = Date.now() + initialTime
    setTargetDate(newTargetDate)
    // countdownRef.current.reset()
    setIsRunning(false)
  }

  // Handling project selection from SpeedDial
  const handleSelectProject = (project) => {
    // Here you can define the logic to handle the selected project
    setOpen(false) // Close the SpeedDial after selection
  }

  return (
    <TimerContainer>
      <TimerButtonsContainer>
        <Fab color="primary" aria-label="add-task" onClick={addTask}>
          <AssignmentIcon />
        </Fab>

          <SpeedDial
            ariaLabel="Project SpeedDial"
            icon={<SpeedDialIcon />}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            direction="down">
            {data?.getProjects.map((project) => (
              <SpeedDialAction
                key={project.elementId}
                icon={<WorkOutlineIcon />}
                tooltipTitle={project.properties.title}
                onClick={() => handleSelectProject(project)}
              />
            ))}
          </SpeedDial>

      </TimerButtonsContainer>
      <Countdown ref={countdownRef} date={targetDate} renderer={renderer} autoStart={false} controlled={false} />
      <TimerButton onClick={toggleCountdown}>{isRunning ? 'Pause' : 'Start'}</TimerButton>
      <TimerButton onClick={resetCountdown}>Reset</TimerButton>
    </TimerContainer>
  )
}

export default CountdownTimer
