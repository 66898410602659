// Import required components and hooks
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import { DialogContext } from '../context/DialogContext.js';

// Import graphQL Mutation
import { useMutation, gql } from '@apollo/client'

// Import languages
import languages from '../languages.js'

const ADD_WORD_MUTATION = gql`
  mutation AddUserWord($source_text: String!, $target_text: String!, $source_language: String!, $target_language: String!) {
    AddUserWord(source_text: $source_text, target_text: $target_text, source_language: $source_language, target_language: $target_language) {
      id
    }
  }
`

// Create the AddWordDialog component
function AddWordDialog() {
  const { dialogStates, openDialog, closeDialog } = React.useContext(DialogContext);
  const [sourceLanguage, setSourceLanguage] = useState(Object.keys(languages)[0])
  const [targetLanguage, setTargetLanguage] = useState(Object.keys(languages)[5])
  const [sourceWord, setSourceWord] = useState('')
  const [targetWord, setTargetWord] = useState('')
  

  // GraphQL
  const [addUserWord, { data, loading, error }] = useMutation(ADD_WORD_MUTATION)

  const handleClose = () => {
    closeDialog('dialogAddWord');
  };

  const handleSourceLanguageChange = (event) => {
    setSourceLanguage(event.target.value)
  }

  const handleTargetLanguageChange = (event) => {
    setTargetLanguage(event.target.value)
  }

  const handleSourceWordChange = (event) => {
    setSourceWord(event.target.value)
  }

  const handleTargetWordChange = (event) => {
    setTargetWord(event.target.value)
  }

  const handleSave = () => {
    addUserWord({
      variables: {
        source_text: sourceWord,
        target_text: targetWord,
        source_language: sourceLanguage,
        target_language: targetLanguage
      }
    })
    handleClose()
    setSourceWord('')
    setTargetWord('')
  }

  return (
    <>
      <Dialog open={dialogStates['dialogAddWord']} onClose={() => closeDialog('dialogAddWord')}>
        <DialogTitle>Add a new word</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="sourceWord"
            label="Source Word"
            type="text"
            fullWidth
            value={sourceWord}
            onChange={handleSourceWordChange}
          />
          <Select labelId="source-language-label" id="sourceLanguage" value={sourceLanguage} onChange={handleSourceLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            id="targetWord"
            label="Target Word"
            type="text"
            fullWidth
            value={targetWord}
            onChange={handleTargetWordChange}
          />
          <Select labelId="target-language-label" id="targetLanguage" value={targetLanguage} onChange={handleTargetLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

// Export AddWordDialog component
export default AddWordDialog
