// Import required components and hooks
import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material'
import { useMutation, gql } from '@apollo/client'
import languages from '../languages.js'
import UserContext from '../context/UserContext.js'
import { DialogContext } from '../context/DialogContext.js'
import TagsInput from './dialoginputs/TagsInput.js'
import DraggableList from '../components/DraggableList'
import { reorder } from '../components/helpers.js'
import { getItems } from '../components/helpers.js'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

// Define GraphQL mutation
const ADD_CONVERSATION_MUTATION = gql`
  mutation AddUserConversation(
    $user: String!
    $start: String!
    $end: String!
    $lang: String!
    $sentences: [SentenceInput]!
    $tags: [String]!
    $people: [PeopleInput]!
    $timeOfDay: String!
  ) {
    AddUserConversation(user: $user, start: $start, end: $end, lang: $lang, sentences: $sentences, tags: $tags, people: $people, timeOfDay: $timeOfDay) {
      conversation {
        id
        start
        end
        lang
        timeOfDay
        people {
          name
          gender
          formality
        }
      }
      sentences {
        id
        text
        lang
        order
      }
    }
  }
`

// Create the AddConversationDialog component
function AddConversationDialog() {
  const { dialogStates, closeDialog } = React.useContext(DialogContext)
  const { username, isLoggedIn } = React.useContext(UserContext)

  // Define states
  const [lang, setLang] = useState(Object.keys(languages)[0])
  const [sentences, setSentences] = useState(getItems(5))
  const [project, setProject] = useState('')
  const [location, setLocation] = useState('')
  const [tags, setTags] = useState([])
  const [people, setPeople] = useState([
    { name: '', gender: '', formality: '' },
    { name: '', gender: '', formality: '' }
  ])
  const [timeOfDay, setTimeOfDay] = useState('')

  // GraphQL
  const [addUserConversation, { data, loading, error }] = useMutation(ADD_CONVERSATION_MUTATION)

  // Function to close dialog
  const handleClose = () => {
    closeDialog('dialogAddConversation')
  }

  // Handle save action
  const handleSave = () => {
    addUserConversation({
      variables: {
        user: username,
        lang: lang,
        sentences: sentences,
        tags: tags,
        people: people,
        timeOfDay: timeOfDay,
        project: project
      }
    })
    handleClose()
    setSentences([])
    setTags([])
    setPeople([
      { name: '', gender: '', formality: '' },
      { name: '', gender: '', formality: '' }
    ])
    setTimeOfDay('')
  }

  return (
    <Dialog open={dialogStates['dialogAddConversation']} onClose={() => closeDialog('dialogAddConversation')}>
      <DialogTitle>Add a new conversation</DialogTitle>
      <DialogContent>
        {/* Speaker 1 and Speaker 2 fields */}
        {people.map((person, index) => (
          <Box display="flex" justifyContent="space-between" key={index}>
            <TextField
              margin="dense"
              label="Name"
              type="text"
              value={person.name}
              onChange={(event) => {
                const newPeople = [...people]
                newPeople[index].name = event.target.value
                setPeople(newPeople)
              }}
            />
            <Select
              value={person.gender}
              onChange={(event) => {
                const newPeople = [...people]
                newPeople[index].gender = event.target.value
                setPeople(newPeople)
              }}>
              <MenuItem value={'M'}>M</MenuItem>
              <MenuItem value={'F'}>F</MenuItem>
            </Select>
            <Select
              value={person.formality}
              onChange={(event) => {
                const newPeople = [...people]
                newPeople[index].formality = event.target.value
                setPeople(newPeople)
              }}>
              <MenuItem value={'Formal'}>Formal</MenuItem>
              <MenuItem value={'Informal'}>Informal</MenuItem>
            </Select>
          </Box>
        ))}
        <TextField margin="dense" id="location" label="Location" type="text" fullWidth value={location} onChange={(event) => setLocation(event.target.value)} />
        <TextField margin="dense" id="project" label="Project" type="text" fullWidth value={project} onChange={(event) => setProject(event.target.value)} />
        {/* Time of day */}
        <FormControl fullWidth>
          <InputLabel id="timeOfDay-label">Time</InputLabel>
          <Select
            labelId="timeOfDay-label"
            id="timeOfDay"
            label="Time of Day"
            value={timeOfDay}
            onChange={(event) => setTimeOfDay(event.target.value)}
            fullWidth>
            <MenuItem value={'Morning'}>Morning</MenuItem>
            <MenuItem value={'Evening'}>Evening</MenuItem>
            <MenuItem value={'Night'}>Night</MenuItem>
          </Select>
        </FormControl>
        {/* Draggable sentences */}
        <DraggableList
          items={sentences}
          onDragEnd={(result) => {
            if (!result.destination) return
            const newSentences = reorder(sentences, result.source.index, result.destination.index)
            setSentences(newSentences)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddConversationDialog
