import { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import PersonIcon from '@mui/icons-material/Person'
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function RelationshipToggleButtons({ visible, relationships, handleRelationshipChange }) {
  console.log(visible)
  return (<>
  {visible && (
    <ToggleButtonGroup color="secondary" value={relationships} onChange={handleRelationshipChange} aria-label="text formatting">
      <ToggleButton style={{ backgroundColor: '#fff' }} value="saved" aria-label="saved">
        <FavoriteIcon />
        SAVED
      </ToggleButton>
      <ToggleButton style={{ backgroundColor: '#fff' }} value="created" aria-label="created">
        <PersonIcon />
        CREATED
      </ToggleButton>
    </ToggleButtonGroup>
  )}
  </>
  )
}
