import React, { useState, useEffect, useContext } from 'react'
import styled, { css, createGlobalStyle, keyframes } from 'styled-components'
import { useLazyQuery, gql, useApolloClient, useQuery } from '@apollo/client'
import { getLanguageAcronym } from './languages.js'
import AddSentenceDialog from './dialog/AddSentenceDialog.js'
import AddButtonLanguage from './UI/AddButtonLanguage.js'
import AddWordDialog from './dialog/AddWordDialog.js'
import LabelToggleButtons from './UI/LabelToggleButtons.js'
import AddButtonLife from './UI/AddButtonLife.js'
import flags from './flags.js'
import SearchIcon from '@mui/icons-material/Search'
import TagIcon from '@mui/icons-material/Tag'
import UserContext from './context/UserContext.js'
import {
  USER_SENTENCES_QUERY,
  TATOEBA_SENTENCES_QUERY,
  GET_INSPIRATIONS_QUERY,
  GET_PROJECTS_QUERY,
  SAVE_SENTENCE_MUTATION,
  USER_SENTENCES_WITH_TRANSLATIONS_QUERY
} from './query/gqlQueries.js'
import { mapUserSentencesData, mapTatoebaSentencesData, mapInspirationData, mapProjectData, mapUserSentencesDataSpecificLang } from './query/queryMappers.js'
import AddInspirationDialog from './dialog/AddInspirationDialog.js'
import AddProjectDialog from './dialog/AddProjectDialog.js'
import { Button } from '@mui/base'
import InfoPanel from './UI/InfoPanel.js'
import AddConversationDialog from './dialog/AddConversationDialog.js'
import RelationshipToggleButtons from './UI/RelationshipToggleButtons.js'
import LabelSpeedDial from './UI/LabelSpeedDial.js'
import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import RefreshIcon from '@mui/icons-material/Refresh'

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt'
import Diversity2Icon from '@mui/icons-material/Diversity2'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import SellIcon from '@mui/icons-material/Sell'
import AddIcon from '@mui/icons-material/Add'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'

import client from './apolloClient'
import CircularProgress from '@mui/material/CircularProgress'
import AddConsumptionDialog from './dialog/AddConsumptionDialogue.js'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import CountdownTimer from './components/CountdownTimer.js'

import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { Typography } from '@mui/material'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'

import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import PostAddIcon from '@mui/icons-material/PostAdd'
import FilterDramaIcon from '@mui/icons-material/FilterDrama'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SmsIcon from '@mui/icons-material/Sms'
import AbcIcon from '@mui/icons-material/Abc'
import MessageIcon from '@mui/icons-material/Message'
import AddSentencesExcelDialog from './dialog/AddSentencesExcelDialog.js'
import InspirationFullView from './UI/InspirationFullView.js'
import TranslationFullView from './UI/TranslationFullView.js'
import SpeechFullView from './UI/SpeechFullView.js'
import LooksIcon from '@mui/icons-material/Looks'
import HallucinationButton from './UI/HallucinationButton.js'
import StorageIcon from '@mui/icons-material/Storage'
import InspirationFullViewMany from './UI/InspirationFullViewMany.js'

export default function Hud({
  sourceLanguage,
  setSourceLanguage,
  targetLanguage,
  setTargetLanguage,
  onData,
  label,
  setLabel,
  owner,
  setOwner,
  labels,
  owners,
  languageOptions,
  tickerVisible,
  sentenceList,
  voiceSpeed
}) {
  const [searchWord, setSearchWord] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { username, isLoggedIn } = useContext(UserContext)

  const {
    loading: inspirationsLoading,
    error: inspirationsError,
    data: inspirationsData
  } = useQuery(GET_INSPIRATIONS_QUERY, {
    variables: { username: username },
    skip: !username // Skip the query if username is not available
  })

  // const [labels, setLabels] = useState(['user'])
  const [relationships, setRelationships] = useState(['created'])
  const handleRelationshipChange = (event, newRelationships) => {
    if (newRelationships.length) {
      setRelationships(newRelationships)
    }
  }
  const handleSelectLabel = (selectedLabel) => {
    setLabel(selectedLabel)
  }

  const [currentInspirationIndex, setCurrentInspirationIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(true)

  const inspirations = inspirationsData ? mapInspirationData(inspirationsData) : []

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false) // Start by hiding the current inspiration

      setTimeout(() => {
        // Change inspiration after the fade out completes
        setCurrentInspirationIndex((prevIndex) => (prevIndex + 1 === inspirations.length ? 0 : prevIndex + 1))
        setIsVisible(true) // Fade in the new inspiration
      }, 1000) // Assuming the fade out takes 1 second
    }, 10000) // Change inspiration every 10 seconds

    return () => clearInterval(interval) // Clean up the interval on component unmount
  }, [inspirations.length, currentInspirationIndex]) // Dependency array ensures effect is reset when inspirations list changes

  const queries = {
    Sentences: {
      My: {
        query: USER_SENTENCES_WITH_TRANSLATIONS_QUERY,
        mapper: mapUserSentencesDataSpecificLang,
        variables: {
          username: username,
          lang: getLanguageAcronym(sourceLanguage),
          translationLang: getLanguageAcronym(targetLanguage)
        },
        // query: USER_SENTENCES_QUERY,
        // mapper: mapUserSentencesData,
        // variables: {
        //   username: username,
        //   lang: getLanguageAcronym(sourceLanguage),
        //   relationships: relationships
        // },
        activeComponents: {
          InfoPanel: {
            saveButton: { active: false, subQuery: null }
          },
          RelationshipToggleButtons: {
            relationshipToggle: { active: true }
          }
        },
        labels: [
          {
            name: 'Person',
            mainIcon: <EmojiPeopleIcon />,
            nodeIcon: <AccessibilityNewIcon />,
            addIcon: <PersonAddIcon />
          },
          {
            name: 'Location',
            mainIcon: <FmdGoodIcon />,
            nodeIcon: <LocationCityIcon />,
            addIcon: <AddLocationAltIcon />
          },
          {
            name: 'Tag',
            mainIcon: <TagIcon />,
            nodeIcon: <SellIcon />,
            addIcon: <AddIcon />
          },
          {
            name: 'Project',
            mainIcon: <AccountTreeIcon />,
            nodeIcon: <ListAltIcon />,
            addIcon: <AddIcon />
          }
        ]
      },
      Tatoeba: {
        // query: USER_SENTENCES_QUERY,
        // mapper: mapUserSentencesData,
        // variables: {
        //   username: username,
        //   lang: getLanguageAcronym(sourceLanguage),
        //   relationships: relationships
        // },
        query: TATOEBA_SENTENCES_QUERY,
        mapper: mapTatoebaSentencesData,
        variables: {
          lang: getLanguageAcronym(sourceLanguage),
          word: searchWord,
          translationLang: getLanguageAcronym(targetLanguage)
        },
        activeComponents: {
          InfoPanel: {
            saveButton: { active: true, subQuery: SAVE_SENTENCE_MUTATION }
          }
        }
      }
    },
    Inspiration: {
      My: {
        query: GET_INSPIRATIONS_QUERY,
        mapper: mapInspirationData,
        variables: {
          username: username
        },
        activeComponents: {
          InfoPanel: {
            saveButton: { active: false, subQuery: null }
          }
        }
      }
    },
    Projects: {
      My: {
        query: GET_PROJECTS_QUERY,
        mapper: mapProjectData,
        variables: {
          username: username
        }
      },
      activeComponents: {
        InfoPanel: {
          saveButton: { active: false, subQuery: null }
        }
      }
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    setError(null)
    const selectedQuery = queries[label][owner].query

    try {
      const { data } = await client.query({
        query: selectedQuery,
        variables: queries[label][owner].variables
      })

      const transformedData = queries[label][owner]?.mapper(data)
      onData(transformedData)

      setLoading(false) // Set loading to false when the query finishes
    } catch (err) {
      setError(err) // If there's an error, set the state
      setLoading(false) // Set loading to false
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleOwnerChange = (event) => {
    setSelectedOwner(event.target.value)
  }

  const handleLabelChange = (event) => {
    setSelectedLabel(event.target.value)
  }

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value)
  }

  const ownerIconMapping = {
    My: <SentimentVerySatisfiedIcon />,
    Tatoeba: <StorageIcon />
  }
  const labelIconMapping = {
    Inspiration: <SelfImprovementIcon />,
    Action: <DirectionsRunIcon />,
    'Self Improvement': <PostAddIcon />,
    Project: <FilterDramaIcon />,
    Thoughts: <PsychologyAltIcon />,
    Question: <TagIcon />,
    Conversations: <QuestionAnswerIcon />,
    Monologue: <MessageIcon />,
    Sentences: <SmsIcon />,
    Words: <AbcIcon />
    // Add other label-icon pairs as needed
  }

  const [isOwnerSpeedDialOpen, setIsOwnerSpeedDialOpen] = useState(false)
  const [isLabelSpeedDialOpen, setIsLabelSpeedDialOpen] = useState(false)
  const [isLanguageSpeedDialOpen, setIsLanguageSpeedDialOpen] = useState(false)

  // Halluciantion Engine
  const [isAllViewVisible, setIsAllViewVisible] = useState(false)
  const [isAllViewTranslationVisible, setIsAllViewTranslationVisible] = useState(false)
  const [isAllViewSpeechVisible, setIsAllViewSpeechVisible] = useState(false)
  const [isAllViewManyInspirationsVisible, setIsAllViewManyInspirationsVisible] = useState(false)

  const handleToggleAllView = () => {
    setIsAllViewVisible((prev) => !prev) // Toggle the visibility state
  }
  const handleToggleAllViewTranslation = () => {
    setIsAllViewTranslationVisible((prev) => !prev) // Toggle the visibility state
  }

  const toggleViewSpeech = () => {
    setIsAllViewSpeechVisible((prev) => !prev) // Toggle the visibility state
  }

  const toggleViewManyInspirations = () => {
    setIsAllViewManyInspirationsVisible((prev) => !prev) // Toggle the visibility state
  }

  const isAnySpeedDialOpen = () => isOwnerSpeedDialOpen || isLabelSpeedDialOpen || isLanguageSpeedDialOpen
  return (
    <>
      <AllView style={{ display: isAllViewVisible ? 'block' : 'none' }}>
        <InspirationFullView
          currentInspirationIndex={currentInspirationIndex}
          setCurrentInspirationIndex={setCurrentInspirationIndex}
          inspirations={inspirations}
          isAllViewVisible={isAllViewVisible}
          setIsAllViewVisible={setIsAllViewVisible}
          currentInspiration={inspirationsLoading ? <p>Loading inspirations...</p> : inspirations[currentInspirationIndex].source}
        />
      </AllView>
      <AllView style={{ display: isAllViewManyInspirationsVisible ? 'block' : 'none' }}>
        <InspirationFullViewMany
          currentInspirationIndex={currentInspirationIndex}
          setCurrentInspirationIndex={setCurrentInspirationIndex}
          inspirations={inspirations}
          isAllViewVisible={isAllViewVisible}
          setIsAllViewManyInspirationsVisible={setIsAllViewManyInspirationsVisible}
          currentInspiration={inspirationsLoading ? <p>Loading inspirations...</p> : inspirations[currentInspirationIndex].source}
        />
      </AllView>
      <AllView style={{ display: isAllViewSpeechVisible ? 'block' : 'none' }}>
        <SpeechFullView
          sentenceList={sentenceList}
          voiceSpeed={voiceSpeed}
          isAllViewSpeechVisible={isAllViewSpeechVisible}
          setIsAllViewSpeechVisible={setIsAllViewSpeechVisible}
          currentInspiration={inspirationsLoading ? <p>Loading inspirations...</p> : inspirations[currentInspirationIndex].source}
        />
      </AllView>
      <AllView style={{ display: isAllViewTranslationVisible ? 'block' : 'none' }}>
        <TranslationFullView
          sentenceList={sentenceList}
          isAllViewTranslationVisible={isAllViewTranslationVisible}
          setIsAllViewTranslationVisible={setIsAllViewTranslationVisible}
          voiceSpeed={voiceSpeed}
          currentTranslation={inspirationsLoading ? <p>Loading inspirations...</p> : inspirations[currentInspirationIndex].source}
        />
      </AllView>
      <TopLeftDisplay>
        <SpeedDialContainerHUD>
          <SpeedDial
            onOpen={() => setIsOwnerSpeedDialOpen(true)}
            onClose={() => setIsOwnerSpeedDialOpen(false)}
            ariaLabel="Owner SpeedDial"
            FabProps={{ variant: 'extended' }}
            icon={
              <Box>
                <Typography> {owner} </Typography>
              </Box>
            }
            direction="down">
            {owners.map((owner) => {
              const icon = ownerIconMapping[owner] || <WorkOutlineIcon />
              return (
                <SpeedDialAction
                  sx={{ fontSize: '18px' }}
                  style={{ variant: 'extended', height: isAnySpeedDialOpen() ? 'auto' : '2px' }}
                  key={owner}
                  icon={icon}
                  tooltipTitle={owner}
                  onClick={() => setOwner(owner)}
                />
              )
            })}
          </SpeedDial>
          <SpeedDial
            onOpen={() => setIsLabelSpeedDialOpen(true)}
            onClose={() => setIsLabelSpeedDialOpen(false)}
            ariaLabel="Label SpeedDial"
            FabProps={{ variant: 'extended' }}
            icon={
              <Box>
                <Typography sx={{ fontSize: '18px' }}> {label} </Typography>
              </Box>
            }
            direction="down">
            {labels.map((label) => {
              const icon = labelIconMapping[label] || <WorkOutlineIcon /> // Default to WorkOutlineIcon if no match
              return (
                <SpeedDialAction
                  key={label}
                  icon={icon}
                  noWrap={true}
                  tooltipOpen
                  tooltipTitle={label}
                  onClick={() => handleSelectLabel(label)}
                  style={{ variant: 'extended', height: isAnySpeedDialOpen() ? 'auto' : '2px' }}
                />
              )
            })}
          </SpeedDial>
          <SpeedDial
            onOpen={() => setIsLanguageSpeedDialOpen(true)}
            onClose={() => setIsLanguageSpeedDialOpen(false)}
            ariaLabel="Language SpeedDial"
            FabProps={{ variant: 'extended' }}
            icon={
              <Box>
                <Typography noWrap={true} sx={{ fontSize: '18px' }}>
                  {flags[getLanguageAcronym(targetLanguage)] + ' ' + targetLanguage}
                </Typography>
              </Box>
            }
            direction="down">
            {languageOptions.map((language) => (
              <SpeedDialAction
                style={{ variant: 'extended', height: isAnySpeedDialOpen() ? 'auto' : '2px' }}
                key={language}
                icon={
                  <Box>
                    <Typography>{flags[getLanguageAcronym(language)]}</Typography>
                  </Box>
                }
                tooltipTitle={language}
                tooltipOpen
                onClick={() => setTargetLanguage(language)}
              />
            ))}
          </SpeedDial>
        </SpeedDialContainerHUD>
      </TopLeftDisplay>
      <LowerLeftUpper>
        <AddButtonsContainer>
          <HallucinationButton
            toggleViewHallucination={handleToggleAllView}
            toggleViewTranslation={handleToggleAllViewTranslation}
            toggleViewSpeech={toggleViewSpeech}
            toggleViewManyInspirations={toggleViewManyInspirations}
          />
          <AddSentenceDialog />
          <AddButtonLanguage />
          <AddWordDialog />
          <AddConversationDialog />
          <AddConsumptionDialog />
          <AddProjectDialog />
          <AddInspirationDialog />
          <AddButtonLife />
        </AddButtonsContainer>
      </LowerLeftUpper>
      <BottomRightDisplay>
        <Fab color="primary" onClick={handleSearch}>
          <RefreshIcon />
        </Fab>
      </BottomRightDisplay>
      <UpperRightDisplay>
        <CountdownTimer initialTime={25 * 60 * 1000} username={username} />
      </UpperRightDisplay>
      <InfoPanel saveButton={queries[label][owner].activeComponents.InfoPanel.saveButton} />
      <SearchContainer>
        {loading && <CircularProgress />}
        {error && <p>Error: {error.message}</p>}
        <SearchBox>
          <SearchInput
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Search for something!"
          />
          <SearchButton href="#">
            <SearchIcon style={{ color: 'inherit' }} />
          </SearchButton>
        </SearchBox>
        {/* <LabelToggleButtons labels={labels} handleLabelChange={handleLabelChange} /> */}
        <RelationshipToggleContainer>
          <RelationshipToggleButtons
            visible={queries[label][owner]?.activeComponents?.RelationshipToggleButtons?.relationshipToggle?.active ?? false}
            relationships={relationships}
            handleRelationshipChange={handleRelationshipChange}
          />
        </RelationshipToggleContainer>
        <h2>Search</h2>
      </SearchContainer>
      <Global />
      <LowerRight isVisible={isVisible} onClick>
        {inspirationsLoading && <p>Loading inspirations...</p>}
        {inspirationsError && <p>Error loading inspirations: {inspirationsError.message}</p>}
        {inspirations.length > 0 && <p>{inspirations[currentInspirationIndex].source}</p>}
        {inspirations.length === 0 && <p>Pronoia: It matters not the past, only what you do now</p>}
      </LowerRight>
      <NewsTicker style={{ visibility: tickerVisible ? 'visible' : 'hidden' }}>
        <div>
          {inspirations.map((inspiration, index) => (
            <span key={index}>{inspiration.source} &nbsp;|&nbsp; </span>
          ))}
        </div>
      </NewsTicker>
    </>
  )
}

const base = css`
  font-family: 'Teko', sans-serif;
  position: absolute;
  text-transform: uppercase;
  font-weight: 900;
  font-variant-numeric: slashed-zero tabular-nums;
  line-height: 1.1 em;
  pointer-events: none;
  color: white;
`

const fadeInRight = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`
const fadeInLeft = keyframes`
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0%);
  }
`
const fadeInDown = keyframes`
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0%);
  }
`

const RelationshipToggleContainer = styled.div`
  animation: ${fadeInRight} 1.5s ease forwards;
`

const InfoPanelDiv = styled.div`
  ${base}
  bottom: 270px;
  right: 100px;
`

const LowerLeftUpper = styled.div`
  ${base}
  animation: ${fadeInLeft} 1s ease forwards;
  bottom: 270px;
  right: 50px;
  @media (max-width: 900px) {
    bottom: 40px;
    right: 30px;
  }
`

const AllView = styled.div`
  ${base}
  display: none;
  top: 0px;
  left: 0px;
  z-index: 1;
  pointer-events: auto;
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column; // if the children are not already stacked vertically
  gap: 20px; // adjust this to change the spacing

  ${base}
  bottom: 80px;
  left: 50px;
  transform: skew(-3deg, -3deg);
  pointer-events: auto;
  & > h1 {
    margin: 0;
    font-size: 10em;
    line-height: 1em;
  }
  & > h2 {
    animation: ${fadeInRight} 1s ease forwards;
    margin: 0;
    font-size: 4em;
    line-height: 1em;
  }
  @media only screen and (max-width: 900px) {
    bottom: 30px;
    & > h1 {
      font-size: 6em !important;
    }
    & > h2 {
      font-size: 3em !important;
    }
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const BottomRightDisplay = styled.div`
  ${base}
  bottom: 40px;
  left: 30px;
  pointer-events: auto;
  animation: ${fadeInRight} 1s ease forwards;

  @media only screen and (min-width: 900px) {
    display: none;
  }
`

const UpperRightDisplay = styled.div`
  ${base}
  animation: ${fadeInLeft} 1.5s ease forwards;
  transform: skew(+3deg, +3deg);
  top: 40px;
  right: 30px;
  pointer-events: auto;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`

const LowerRight = styled.div`
  ${base}
  bottom: 200px;
  font-size: 1.7em;
  right: 50px;
  transform: skew(3deg, 3deg);
  height: 40px;
  width: 320px;
  background: black;
  transition: opacity 1s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  & > div {
    height: 100%;
    background: indianred;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    user-select: none;
    overflow: hidden;
  }

  #root {
    overflow: auto;
    padding: 0px;
  }

  body {
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    
  
  }
`

const SearchBox = styled.div`
  animation: ${fadeInRight} 2s ease forwards;
  background: #1976d2;
  height: 60px;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
`

const SearchInput = styled.input`
  outline: none;
  border: none;
  background: none;
  width: 55px;
  padding: 0;
  color: #fff;
  float: left;
  font-size: 16px;
  line-height: 40px;
  transition: width 0.8s ease-in-out, padding 0.8s ease-in-out;

  &::placeholder {
    color: #dbc5b0;
  }

  &:hover,
  &:focus,
  &:not(:placeholder-shown) {
    width: 240px;
    padding: 0 6px;
  }
`

const SearchButton = styled.a`
  color: #fff;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: 2s;

  ${SearchInput}:hover + &,
  ${SearchInput}:focus + &,
  ${SearchInput}:not(:placeholder-shown) + & {
    background: #fff;
    color: #cd595a;
  }
`

const TopLeftDisplay = styled.div`
  display: flex;
  flex-direction: column;
  transform: skew(+2deg, +2deg);
  position: absolute;
  top: 0px;
  left: 0px;
  @media only screen and (max-width: 900px) {
    transform: skew(0deg, 0deg);
  }
`

const LabelDisplay = styled.div`
  width: 350px;
  height: 80px;
  background: #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #fff;
  border-bottom-right-radius: 50px;
  @media (max-width: 769px) {
    width: 220px;
    height: 40px;
    font-size: 16px;
  }
`
const SpeedDialContainerHUD = styled.div`
  animation: ${fadeInDown} 2s ease forwards;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 35px;
  left: 50px;
  padding: 10px;
  gap: 10px;
  @media (max-width: 769px) {
    top: 5px;
    left: 0px;
  }
`

const LanguageDisplay = styled.div`
  width: 250px;
  height: 80px;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #fff;
  border-bottom-right-radius: 50px;

  @media (max-width: 769px) {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }
`

const TagDisplay = styled.div`
  width: 200px;
  height: 80px;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #fff;
  border-bottom-right-radius: 50px;

  @media (max-width: 769px) {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }
`

const LabelContainer = styled.div`
  ${base}
  bottom: 30vh;
  right: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center; // this will vertically center your elements
  align-items: center; // this will horizontally center your elements
  gap: 20px; // this adds space between your elements
`

const StyledSelect = styled(Select)`
  width: 100%; // Adjust as needed
  height: 100%; // Adjust as needed
  background: #1976d2;
  color: white;
  font-size: 26px;
  border-bottom-right-radius: 50px;

  & .MuiSelect-select {
    padding: 10px; // Adjust as needed
  }

  @media (max-width: 769px) {
    font-size: 16px;
  }
`

const scrollAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`

const NewsTicker = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: black;
  color: white;
  font-size: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 0;

  & > div {
    display: inline-block;
    padding-left: 60%; // Ensures the text starts from the right
    animation: ${scrollAnimation} 90s linear infinite;
    font-family: 'LCD Solid';
    @media (max-width: 769px) {
      animation: ${scrollAnimation} 270s linear infinite;
    }
  }

  @media (max-width: 769px) {
    font-size: 0.9em;
  }
`

const AddButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  transform: skew(+3deg, +3deg);
  @media only screen and (max-width: 900px) {
    transform: skew(0deg, 0deg);
  }
`

const HallucinationEngineButton = styled.div`
  align-self: flex-end;
  z-index: 1052;
`
