export function speak(text, voiceURI, voiceSpeed) {
  const speakWithVoice = () => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text)
      const voices = speechSynthesis.getVoices()
      const selectedVoice = voices.find((voice) => voice.voiceURI === voiceURI)
      if (selectedVoice) {
        utterance.voice = selectedVoice
      }
      utterance.rate = voiceSpeed
      speechSynthesis.speak(utterance)
      console.log('Text: ', text)
      console.log('Voice URI: ', voiceURI)
    } else {
      console.log('Web Speech API not supported')
    }
  }

  if (speechSynthesis.getVoices().length === 0) {
    speechSynthesis.addEventListener('voiceschanged', speakWithVoice)
  } else {
    speakWithVoice()
  }
}
