import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SmsIcon from '@mui/icons-material/Sms'
import AbcIcon from '@mui/icons-material/Abc'
import MessageIcon from '@mui/icons-material/Message'
import QueueIcon from '@mui/icons-material/Queue';

import { DialogContext } from '../context/DialogContext.js'

export default function AddButtonLanguage() {
  const { dialogStates, openDialog, closeDialog } = React.useContext(DialogContext)

  const actions = [
    // { icon: <QuestionAnswerIcon />, name: 'Conversation', onClick: () => openDialog('dialogAddConversation') },
    // { icon: <MessageIcon />, name: 'Monologue' },
    { icon: <SmsIcon />, name: 'Sentence', onClick: () => openDialog('dialogAddSentence') },
    // { icon: <QueueIcon />, name: 'Sentences', onClick: () => openDialog('dialogAddSentencesExcel') },
    // { icon: <AbcIcon />, name: 'Word', onClick: () => openDialog('dialogAddWord') }
  ]

  return (
      <SpeedDial direction="up" ariaLabel="SpeedDialLanguage" icon={<SpeedDialIcon />}>
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
        ))}
      </SpeedDial>
  )
}
