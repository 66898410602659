// Import required components and hooks
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DialogContext } from '../context/DialogContext.js';
import UserContext from '../context/UserContext.js';

// Import graphQL Mutation
import { useMutation, gql } from '@apollo/client';

const ADD_CONSUMPTION_MUTATION = gql`
  mutation AddConsumption($username: String!, $name: String!, $amount: Float!, $units: String!, $time: DateTime!) {
    addConsumption(input: { username: $username, name: $name, amount: $amount, units: $units, time: $time }) {
      identity
      labels
      properties {
        name
        amount
        units
        time
        username
      }
      elementId
    }
  }
`;


// Create the AddConsumptionDialog component
function AddConsumptionDialog() {
  const { dialogStates, closeDialog } = React.useContext(DialogContext);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [units, setUnits] = useState('');
  const [time, setTime] = useState(new Date().toISOString().slice(0, 16));
  const { username, isLoggedIn } = React.useContext(UserContext);

  // GraphQL
  const [addConsumption, { data, loading, error }] = useMutation(ADD_CONSUMPTION_MUTATION);

  const handleClose = () => {
    closeDialog('dialogAddConsumption');
  };

  const handleSave = () => {
   
      addConsumption({
        variables: {
          name,
          amount: parseFloat(amount),
          units,
          time,
          username
        }
      });

    handleClose();
  };

  return (
    <Dialog open={dialogStates['dialogAddConsumption']} onClose={() => closeDialog('dialogAddConsumption')}>
      <DialogTitle>Add a new Consumption</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <TextField
          margin="dense"
          id="units"
          label="Units"
          type="text"
          fullWidth
          value={units}
          onChange={(e) => setUnits(e.target.value)}
        />
        <TextField
          margin="dense"
          id="time"
          label="Time"
          type="datetime-local"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={loading}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddConsumptionDialog;
