// Import required components and hooks
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { DialogContext } from '../context/DialogContext.js'
import Select from '@mui/material/Select'
import UserContext from '../context/UserContext.js'

// Import graphQL Mutation
import { useMutation, gql } from '@apollo/client'

// Import languages
import languages from '../languages.js'

const ADD_INSPIRATION_MUTATION = gql`
  mutation AddInspiration($username: String!, $text: String!, $lang: String!) {
    addInspiration(input: { username: $username, text: $text, lang: $lang }) {
      identity
      labels
      properties {
        text
        lang
        username
      }
      elementId
    }
  }
`

// Create the AddInspirationDialog component
function AddInspirationDialog() {
  const { dialogStates, closeDialog } = React.useContext(DialogContext)
  const [language, setLanguage] = useState(Object.keys(languages)[0])
  const [inspirationText, setInspirationText] = useState('')
  const { username, isLoggedIn } = React.useContext(UserContext)
  

  // GraphQL
  const [addInspiration, { data, loading, error }] = useMutation(ADD_INSPIRATION_MUTATION)

  const handleClose = () => {
    closeDialog('dialogAddInspiration')
  }

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value)
  }

  const handleInspirationTextChange = (event) => {
    setInspirationText(event.target.value)
  }

  const handleSave = () => {
    addInspiration({
      variables: {
        username: username,
        text: inspirationText,
        lang: language
      }
    })
    handleClose()
    setInspirationText('')
  }

  return (
    <>
      <Dialog open={dialogStates['dialogAddInspiration']} onClose={() => closeDialog('dialogAddInspiration')}>
        <DialogTitle>Add a new Inspiration</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="inspirationText"
            label="Inspiration Text"
            type="text"
            fullWidth
            value={inspirationText}
            onChange={handleInspirationTextChange}
          />
          <Select labelId="language-label" id="language" value={language} onChange={handleLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

// Export AddInspirationDialog component
export default AddInspirationDialog
