import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import InboxIcon from '@mui/icons-material/Inbox';

const DraggableListItem = styled(ListItem)(({ theme }) => ({
  '&.dragging': {
    background: 'rgb(235,235,235)'
  },
}));

const DraggableItem = (props) => {
  const { item, index } = props;
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <DraggableListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? 'dragging' : ''}
        >
          <ListItemAvatar>
            <Avatar>
              <InboxIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />
        </DraggableListItem>
      )}
    </Draggable>
  );
};

export default DraggableItem;
