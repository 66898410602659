import * as React from 'react'
import { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import './InspirationFullView.css'
import { useGSAP } from '@gsap/react'
import DeviceContext from '../context/DeviceContext'

gsap.registerPlugin(SplitText) // Make sure the SplitText plugin is registered

export default function InspirationFullView({
  currentInspiration,
  currentInspirationIndex,
  setCurrentInspirationIndex,
  inspirations,
  isAllViewVisible,
  setIsAllViewVisible
}) {
  const inspirationRef = useRef(null) // Ref to target the inspiration text
  const { isMobile } = useContext(DeviceContext)

  useGSAP(() => {
    if (inspirationRef.current) {
      // Split the inspiration text into words
      const splitInspiration = new SplitText(inspirationRef.current, {
        type: 'words'
      })

      // Animate the inspiration words
      gsap.from(splitInspiration.words, {
        opacity: 0,
        y: 60,
        stagger: 0.15,
        duration: 2,
        ease: 'circ.out'
      })

      // Clean up the SplitText instance when the component unmounts
      return () => {
        splitInspiration.revert()
      }
    }
  }, [currentInspiration]) // Re-run animation if the inspiration text changes

  // Handle key presses for desktop
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isMobile && isAllViewVisible) {
        if (event.key === 'ArrowRight') {
          // Move to the next inspiration
          setCurrentInspirationIndex((prevIndex) => (prevIndex + 1 === inspirations.length ? 0 : prevIndex + 1))
        } else if (event.key === 'ArrowLeft') {
          // Move to the previous inspiration
          setCurrentInspirationIndex((prevIndex) => (prevIndex - 1 < 0 ? inspirations.length - 1 : prevIndex - 1))
        } else if (event.key === 'e') {
          setIsAllViewVisible(false)
        } else if (event.key === 'r' || event.key === 'R') {
          // Select a random sentence
          const randomIndex = Math.floor(Math.random() * inspirations.length)
          setCurrentInspirationIndex(randomIndex)
        }
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [inspirations.length, isMobile, isAllViewVisible])

  console.log('Number of Inspirations:', inspirations.length)

  return (
    <div className="inspiration-full-view">
      <div className="inspiration" ref={inspirationRef}>
        {currentInspiration}
      </div>
    </div>
  )
}
