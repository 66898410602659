import * as React from 'react'
import { useRef, useEffect, useContext } from 'react'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { useGSAP } from '@gsap/react'
import DeviceContext from '../context/DeviceContext'
import './InspirationFullView.css'
import styles from './InspirationFullViewMany.module.scss'

gsap.registerPlugin(SplitText) // Make sure the SplitText plugin is registered

export default function InspirationFullViewMany({
  currentInspiration,
  currentInspirationIndex,
  setCurrentInspirationIndex,
  inspirations,
  isAllViewVisible,
  setIsAllViewVisible
}) {
  const inspirationRefs = useRef([]) // Ref array to target multiple inspiration elements
  const { isMobile } = useContext(DeviceContext)

  useGSAP(() => {
   
  }, [currentInspiration, inspirations]) // Re-run animation if inspirations change

  // Handle key presses for desktop
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isMobile && isAllViewVisible) {
        if (event.key === 'ArrowRight') {
          // Move to the next inspiration
          setCurrentInspirationIndex((prevIndex) => (prevIndex + 1 === inspirations.length ? 0 : prevIndex + 1))
        } else if (event.key === 'ArrowLeft') {
          // Move to the previous inspiration
          setCurrentInspirationIndex((prevIndex) => (prevIndex - 1 < 0 ? inspirations.length - 1 : prevIndex - 1))
        } else if (event.key === 'e') {
          setIsAllViewVisible(false)
        } else if (event.key === 'r' || event.key === 'R') {
          // Select a random sentence
          const randomIndex = Math.floor(Math.random() * inspirations.length)
          setCurrentInspirationIndex(randomIndex)
        }
      }
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [inspirations.length, isMobile, isAllViewVisible])

  console.log('Number of Inspirations:', inspirations.length)


  return (
    <div className={styles.inspirationFullView}>
      {inspirations.length > 0 &&
        inspirations.map((inspiration, index) => (
          <div key={index} className={styles.inspiration} ref={(el) => (inspirationRefs.current[index] = el)}>
            {inspiration.source}
          </div>
        ))}
    </div>
  )
}
