// src/index.js
import { createRoot } from 'react-dom/client'
import React from 'react'
import './styles.css'
import App from './App'
import { ApolloProvider } from '@apollo/client'
import client from './apolloClient'

const Root = () => (
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>
)

// Create a root using the new createRoot API
const root = createRoot(document.getElementById('root'))

// Render your app using the new root API
root.render(<Root />)
