import * as THREE from 'three'
import { useRef, useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useFrame } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import SentenceContext from './context/SentenceContext'
import DeviceContext from './context/DeviceContext'
import { speak } from './speech/speak.js'
import { splitText } from './text/splitText'
import voices from './speech/voices'
import { getLanguageAcronym } from './languages'
import fonts from './fonts/fonts'
import { Button } from '@mui/material'

export function Sentence({
  sentenceObj,
  fontSize,
  hoverColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  targetLanguage,
  sourceLanguage,
  voiceSpeed,
  ...props
}) {
  const [textScale, setTextScale] = useState(1)
  const { isMobile } = useContext(DeviceContext)

  const color = new THREE.Color()
  const fontProps = { font: fonts[sentenceObj.targetLanguage], fontSize, letterSpacing: -0.05, lineHeight: 1 }
  const ref = useRef()
  const refTranslation = useRef()
  const [hovered, setHovered] = useState(false)
  const [hoveredTranslation, setHoveredTranslation] = useState(false)
  const over = (e) => (e.stopPropagation(), setHovered(true))
  const out = () => setHovered(false)
  const overTranslation = (e) => (e.stopPropagation(), setHoveredTranslation(true))
  const outTranslation = () => setHoveredTranslation(false)
  const { activeSentenceObj, setActiveSentenceObj } = useContext(SentenceContext)

  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered || hoveredTranslation) document.body.style.cursor = 'pointer'
    else document.body.style.cursor = 'auto'
  }, [hovered, hoveredTranslation])

  // Memoize the split texts
  const wordsPerLine = 5
  const targetText = useMemo(() => splitText(sentenceObj.target, wordsPerLine), [sentenceObj.target])
  const translationText = useMemo(() => splitText(sentenceObj.source, wordsPerLine), [sentenceObj.source])

  // Update useFrame hook
  useFrame(({ camera }) => {
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion)
    refTranslation.current.quaternion.copy(camera.quaternion)

    // Create offset in camera's local space
    const offset = new THREE.Vector3(0, -3, 0)

    // Transform offset to world space and add to position of original text
    refTranslation.current.position.copy(ref.current.position).add(offset.applyQuaternion(camera.quaternion))

    // Animate font color
    ref.current.material.color.lerp(color.set(hovered ? hoverColor : 'white'), 0.1)
    refTranslation.current.material.color.lerp(color.set(hoveredTranslation ? secondaryColor : primaryColor), 0.1)

    // Animate text scale
    const targetScale = hovered || activeSentenceObj === sentenceObj ? 1.1 : 1
    setTextScale(THREE.MathUtils.lerp(textScale, targetScale, 0.1))

    // Animate opacity
    if (activeSentenceObj === sentenceObj) {
      ref.current.material.opacity = Math.min(ref.current.material.opacity + 0.05, 1)
      refTranslation.current.material.opacity = Math.min(refTranslation.current.material.opacity + 0.05, 1)
    } else {
      const targetOpacity = activeSentenceObj ? 0.2 : 1
      ref.current.material.opacity = THREE.MathUtils.lerp(ref.current.material.opacity, targetOpacity, 0.1)
      refTranslation.current.material.opacity = THREE.MathUtils.lerp(refTranslation.current.material.opacity, targetOpacity, 0.1)
    }
  })

  const handleClick = useCallback(() => {
    setActiveSentenceObj(sentenceObj)
    speak(sentenceObj.target, voices[sentenceObj.targetLanguage], voiceSpeed)
  }, [setActiveSentenceObj, sentenceObj, voiceSpeed])

  useEffect(() => {
    if (activeSentenceObj === sentenceObj) {
      ref.current.text = targetText
      refTranslation.current.text = translationText
    } else {
      ref.current.text = targetText
      refTranslation.current.text = ''
    }
  }, [targetText, translationText, sentenceObj, activeSentenceObj])

  return (
    <>
      <Text
        ref={ref}
        onPointerOver={over}
        onPointerOut={out}
        onClick={isMobile ? undefined : handleClick}
        onPointerUp={isMobile ? handleClick : undefined}
        {...props}
        {...fontProps}
        children={sentenceObj.source}
        scale={[textScale, textScale, 1]}
        anchorX="center"
        anchorY="middle"
        textAlign="center"
      />
      <Text
        ref={refTranslation}
        onPointerOver={overTranslation}
        onPointerOut={outTranslation}
        {...props}
        {...fontProps}
        scale={[textScale, textScale, 1]}
        anchorX="center"
        anchorY="middle"
        textAlign="center"
      />
    </>
  )
}
