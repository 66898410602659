import React from 'react'

export const DialogContext = React.createContext({
  dialogStates: {
    dialogAddWord: false,
    dialogAddSentence: false,
    dialogAddInspiration: false,  
  },
  openDialog: () => {},
  closeDialog: () => {}
})

export const DialogProvider = ({ children }) => {
  const [dialogStates, setDialogStates] = React.useState({
    dialogAddWord: false,
    dialogAddSentence: false,
    dialogAddInspiration: false, 
  });

  const openDialog = (dialogName) => {
    setDialogStates((prevState) => ({ ...prevState, [dialogName]: true }))
  }

  const closeDialog = (dialogName) => {
    setDialogStates((prevState) => ({ ...prevState, [dialogName]: false }))
  }

  return (
    <DialogContext.Provider value={{ dialogStates, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
}
