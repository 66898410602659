// Import required components and hooks
import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DialogContext } from '../context/DialogContext.js';
import Select from '@mui/material/Select';
import UserContext from '../context/UserContext.js'
import EmojiPicker from 'emoji-picker-react';

// Import graphQL Mutation
import { useMutation, gql } from '@apollo/client';

// Import languages
import languages from '../languages.js';

const ADD_PROJECT_MUTATION = gql`
  mutation AddProject($username: String!, $title: String!, $lang: String!) {
    addProject(input: { username: $username, title: $title, lang: $lang }) {
      identity
      labels
      properties {
        title
        lang
        dateAdded
        dateLastModified
        username
      }
      elementId
    }
  }
`;

// Create the AddProjectDialog component
function AddProjectDialog() {
  const { dialogStates, closeDialog } = useContext(DialogContext);
  const [language, setLanguage] = useState(Object.keys(languages)[0]);
  const [projectTitle, setProjectTitle] = useState('');
  const [icon, setIcon] = useState('');  // State for the emoji icon
  const { username, isLoggedIn } = useContext(UserContext);

  // GraphQL
  const [addProject, { data, loading, error }] = useMutation(ADD_PROJECT_MUTATION);

  const handleClose = () => {
    closeDialog('dialogAddProject');
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleProjectTitleChange = (event) => {
    setProjectTitle(event.target.value);
  };

  const onEmojiClick = (event, emojiObject) => {
    setIcon(emojiObject.emoji);
  };
  

  const handleSave = () => {
    addProject({
      variables: {
        username,
        title: projectTitle,
        lang: language,
        // icon,
      },
    });
    handleClose();
    setProjectTitle('');
    setIcon('');
  };

  console.log(icon)

  return (
    <>
      <Dialog open={dialogStates['dialogAddProject']} onClose={() => closeDialog('dialogAddProject')}>
        <DialogTitle>Add a new Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="projectTitle"
            label="Project Title"
            type="text"
            fullWidth
            value={projectTitle}
            onChange={handleProjectTitleChange}
          />
          <Select labelId="language-label" id="language" value={language} onChange={handleLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {/* <div>
            {icon}
          </div>
          <EmojiPicker onEmojiClick={onEmojiClick} /> */}
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Export AddProjectDialog component
export default AddProjectDialog;
