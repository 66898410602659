import * as THREE from 'three'
import { useMemo } from 'react'
import { Sentence } from './sentence'
// import { Inspiration } from './inspiration'

export function Cloud({
  count = 4,
  radius = 20,
  fontSize = 2.5,
  sentenceList,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  hoverColor,
  targetLanguage,
  sourceLanguage,
  voiceSpeed,
  label,
}) {
  // Create a count x count list of setences with spherical distribution
  const sentences = useMemo(() => {
    const temp = []
    const spherical = new THREE.Spherical()
    const phiSpan = Math.PI / (count + 1)
    const thetaSpan = (Math.PI * 2) / count
    for (let i = 1; i < count + 1; i++)
      for (let j = 0; j < count; j++) {
        const index = Math.floor(Math.random() * sentenceList.length)
        temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), sentenceList[index]])
      }
    return temp
  }, [count, radius, sourceLanguage, targetLanguage, sentenceList])


  return sentences.map(([pos, sentenceObj], index) => (
    <Sentence
      key={index}
      position={pos}
      sentenceObj={sentenceObj}
      fontSize={fontSize}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      tertiaryColor={tertiaryColor}
      hoverColor={hoverColor}
      targetLanguage={targetLanguage}
      sourceLanguage={sourceLanguage}
      voiceSpeed={voiceSpeed}
    />
  ))
}
