// Function to detect Safari or Chrome
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const isChrome = /chrome|chromium|crios/i.test(navigator.userAgent) && /google inc/i.test(navigator.vendor)

// Define voices for different browsers
const chromeVoices = {
  eng: 'Arthur',
  nld: 'Xander',
  spa: 'Mónica',
  por: 'Joana',
  rus: 'Milena (Russian (Russia))',
  deu: 'Anna',
  fra: 'Amelie',
  ita: 'Alice',
  jpn: 'Kyoko',
  cmn: 'Tingting',
  vie: 'Linh (Vietnamese (Vietnam))',
  kor: 'Yuna',
  ara: 'Maged',
  ell: 'Melina',
  swe: 'Alva',
  tur: 'Yelda'
}

const safariVoices = {
  eng: 'com.apple.voice.compact.en-GB.Siri',
  nld: 'com.apple.voice.compact.nl-NL.Xander',
  spa: 'com.apple.voice.compact.es-ES.Monica',
  por: 'com.apple.voice.compact.pt-PT.Joana',
  rus: 'com.apple.voice.compact.ru-RU.Milena',
  deu: 'com.apple.voice.compact.de-DE.Anna',
  fra: 'com.apple.voice.compact.fr-CA.Amelie',
  ita: 'com.apple.voice.compact.it-IT.Alice',
  jpn: 'com.apple.voice.compact.ja-JP.Kyoko',
  cmn: 'com.apple.voice.compact.zh-CN.Tingting',
  vie: 'com.apple.voice.compact.vi-VN.Linh',
  kor: 'com.apple.voice.compact.ko-KR.Yuna',
  ara: 'com.apple.voice.compact.ar-001.Maged',
  ell: 'com.apple.voice.compact.el-GR.Melina',
  swe: 'com.apple.voice.compact.sv-SE.Alva',
  tur: 'com.apple.voice.compact.tr-TR.Yelda'
}

console.log('is Safari', isSafari)
// Choose the correct voices object based on the browser
const voices = isSafari ? safariVoices : chromeVoices

// Export the dynamically selected voices based on the browser
export default voices
