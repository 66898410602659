// Import required components and hooks
import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useMutation, gql } from '@apollo/client'
import readXlsxFile from 'read-excel-file'
import { DialogContext } from '../context/DialogContext.js'
import UserContext from '../context/UserContext.js'
import { Box, LinearProgress } from '@mui/material'
import TagsInput from './dialoginputs/TagsInput.js'
import languages, { getLanguageAcronym } from '../languages.js'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

// GraphQL mutation for adding sentences
const ADD_SENTENCE_MUTATION = gql`
  mutation AddUserSentence($username: String!, $sourceText: String!, $targetText: String!, $sourceLang: String!, $targetLang: String!, $tags: [String]!) {
    AddUserSentence(username: $username, sourceText: $sourceText, targetText: $targetText, sourceLang: $sourceLang, targetLang: $targetLang, tags: $tags) {
      sentence {
        id
        text
        lang
      }
      translations {
        id
        text
        lang
      }
    }
  }
`

// Create the AddSentenceDialog component
function AddSentencesExcelDialog() {
  const { dialogStates, closeDialog } = useContext(DialogContext)
  const { username } = useContext(UserContext)
  const [file, setFile] = useState(null)
  const [tags, setTags] = useState([])
  const [uploadStatus, setUploadStatus] = useState('idle')
  // const [sourceLanguage, setSourceLanguage] = useState(Object.keys(languages)[0])
  // const [targetLanguage, setTargetLanguage] = useState(Object.keys(languages)[5])

  // GraphQL mutation hook
  const [addUserSentence, { loading, error }] = useMutation(ADD_SENTENCE_MUTATION)

  // const handleSourceLanguageChange = (event) => {
  //   setSourceLanguage(event.target.value)
  // }

  // const handleTargetLanguageChange = (event) => {
  //   setTargetLanguage(event.target.value)
  // }

  // Function to handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  // Function to process and upload the Excel file
  const handleFileUpload = () => {
    if (file) {
      setUploadStatus('loading')
      readXlsxFile(file).then((rows) => {
        // Capture the language headers before slicing the rows
        const sourceLangHeader = getLanguageAcronym(rows[0][0])
        const targetLangHeader = getLanguageAcronym(rows[0][1])

        // Process the data rows, skipping the header
        const uploadPromises = rows.slice(1).map((row) => {
          const sourceText = row[0]
          const targetText = row[1]
          return addUserSentence({
            variables: {
              username: username,
              sourceText: sourceText,
              targetText: targetText,
              sourceLang: sourceLangHeader,
              targetLang: targetLangHeader,
              tags: tags
            }
          })
        })

        Promise.all(uploadPromises)
          .then(() => setUploadStatus('completed'))
          .catch((error) => {
            console.error('Upload failed:', error)
            setUploadStatus('error')
          })
      })
    }
  }

  const handleClose = () => {
    closeDialog('dialogAddSentencesExcel')
    setFile(null)
    setUploadStatus('idle')
  }

  return (
    <Dialog open={dialogStates['dialogAddSentencesExcel']} onClose={handleClose}>
      <DialogTitle>Add a new sentence</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
          <TagsInput selectedTags={setTags} fullWidth variant="outlined" id="tags" name="tags" placeholder="Add Tags" label="Tags" />
          {uploadStatus === 'loading' && <LinearProgress />}
        </Box>
        {/* <Box mt={2}>
          <Select labelId="target-language-label" id="targetLanguage" value={targetLanguage} onChange={handleTargetLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>

          <Select labelId="source-language-label" id="sourceLanguage" value={sourceLanguage} onChange={handleSourceLanguageChange} fullWidth>
            {Object.entries(languages).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFileUpload} disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
      {error && <p>Error occurred while uploading: {error.message}</p>}
    </Dialog>
  )
}

// Export AddSentenceDialog component
export default AddSentencesExcelDialog
