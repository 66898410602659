import React, { useState, useRef, useEffect } from 'react'
import styles from './speech.module.scss'

export default function SpeechRecognitionComponent({ onSpeechResult, setSpokenLang }) {
  const recognitionRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const [isStoppedManually, setIsStoppedManually] = useState(false)
  const [language, setLanguage] = useState('eng') // State to manage selected language

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    if (!SpeechRecognition) {
      console.error('SpeechRecognition API is not supported in this browser.')
      return
    }

    recognitionRef.current = new SpeechRecognition()
    recognitionRef.current.lang = language // Use selected language
    recognitionRef.current.interimResults = false

    recognitionRef.current.onstart = () => {
      setIsActive(true)
    }

    recognitionRef.current.onspeechend = () => {
      if (!isStoppedManually) {
        recognitionRef.current.stop()
      }
    }

    recognitionRef.current.onend = () => {
      setIsActive(false)
      setIsStoppedManually(false)
    }

    recognitionRef.current.onresult = (event) => {
      const transcript = event.results[0][0].transcript
      onSpeechResult(transcript)
    }
  }, [onSpeechResult, language, isStoppedManually])

  const handleOnRecord = () => {
    if (isActive) {
      setIsStoppedManually(true)
      recognitionRef.current?.stop()
    } else {
      recognitionRef.current?.start()
    }
  }

  return (
    <div className={styles.languageSelector}>
      <select value={language} onChange={(e) => setSpokenLang(e.target.value)}>
        <option value="eng">English (US)</option>
        <option value="fra">French</option>
        <option value="spa">Spanish</option>
        <option value="deu">German</option>
        <option value="por">Portuguese (Brazil)</option>
        <option value="cmn">Chinese (Mandarin)</option>
        <option value="jpn">Japanese</option>
        {/* Add more languages as needed */}
      </select>

      <button onClick={handleOnRecord}>{isActive ? 'Stop Recording' : 'Start Recording'}</button>
    </div>
  )
}
